<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
        <div class="col col-lg-2"></div>
        <div class="col-md-auto mt-20">
          <rotate-square5></rotate-square5>
        </div>
        <div class="col col-lg-2"></div>
      </div>
      <div class="card card-body printableArea" v-else>
        <h3>
          <div class="row">
            <div class="col-md-10 col-12">
              <b>Surat Masuk Terproses</b>
            </div>
            <div class="col-md-2 col-12" v-if="canSeeAll">
              <select
                class="pull-right form-control"
                v-model="from"
                @change="getFrom"
              >
                <option value="me">Diri Sendiri</option>
                <option value="all">Satu Unit</option>
              </select>
            </div>
            <div style="width: 800px; margin: 0 auto">
              <div class="container">
                <div class="row justify-content-md-center">
                  <div class="col-md-12">
                    <form method="post" @submit.prevent="changeDt">
                      <div class="form-body">
                        <div class="row justify-content-md-center">
                          <div class="col-md-9 col-sm-12">
                            <div class="row">
                              <div class="form-group" style="width: 300px">
                                <input
                                  placeholder="Pencarian Nomor Surat"
                                  type="text"
                                  class="form-control"
                                  v-model="cari"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row justify-content-md-center">
                          <div class="col-md-9 col-sm-12">
                            <div class="row">
                              <div class="form-group" style="width: 300px">
                                <input
                                  placeholder="Pencarian Nomor Kendali"
                                  type="text"
                                  class="form-control"
                                  v-model="cari3"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row justify-content-md-center">
                          <div class="col-md-9 col-sm-12">
                            <div class="row">
                              <div class="form-group" style="width: 300px">
                                <input
                                  placeholder="Pencarian Isi Ringkas"
                                  type="text"
                                  class="form-control"
                                  v-model="cari2"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row justify-content-md-center">
                          <div class="col-md-9 col-sm-12">
                            <div class="row">
                              <div class="form-group" style="width: 300px">
                                <input
                                  placeholder="Pencarian Asal Surat"
                                  type="text"
                                  class="form-control"
                                  v-model="asal"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-actions">
                        <div class="row justify-content-md-center">
                          <div class="col-md-7 pl-5">
                            <button
                              type="submit"
                              class="btn btn-success"
                              title="Simpan"
                            >
                              Submit</button
                            >&nbsp;
                            <button
                              type="button"
                              @click="resetInputs()"
                              class="btn btn-inverse"
                              title="Reset"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </h3>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <Table
              :items="items"
              :filter="filters"
              :header="headers"
              :onAction="get"
              :onDetail="doDetail"
              :onDetailTL="doDetailTL"
              :overideSearch="search"
              @onSearch="onSearch"
              :forbidDetail="forbidRead"
              :forbidDelete="true"
            />
          </div>
        </div>
      </div>

      <hr />
      <div class="card card-body printableArea">
        <h4>Petunjuk</h4>
        <table>
          <tr>
            <td>
              <span class="btn-sm btn-success mr-1">
                <i class="mdi mdi-magnify"></i>
              </span>
            </td>
            <td style="font-size: 13px">
              Untuk melihat detail surat yang sudah terproses / terdisposisi
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import Table from "@/components/table/table";
import { RotateSquare5 } from "vue-loading-spinner";

export default {
  components: {
    RotateSquare5,
    Table,
  },
  data() {
    let last = localStorage.getItem("last_request");
    last = last != null ? last.split("?") : ",?,".split("?");
    let params = {};
    if (last[0].includes("document_in")) {
      params = JSON.parse(last[1]);
    }
    if (params.approval == undefined || params.approval != 1) {
      params = {};
    }
    return {
      from: "all",
      search: params.search || "",
      headers: {
        description: {
          label: "Isi Ringkas",
          sortable: true,
        },
        nomorsurat: {
          label: "Nomor Surat",
          sortable: true,
        },
        no_opd: {
          label: "Nomor Kendali",
          sortable: true,
        },
        asal_surat: {
          label: "Asal Surat",
          sortable: false,
        },
        penerima: {
          label: "Tujuan Disposisi",
        },
        tanggal_surat: {
          label: "Tanggal Surat",
          sortable: true,
        },
        status_cancel: {
          label: "Status",
          sortable: true,
        },
      },
    };
  },
  computed: {
    items() {
      let data = [];
      if (this.$store.state.documentInSended.items !== undefined) {
        if (this.$store.state.documentInSended.items.items !== undefined) {
          if (
            !Array.isArray(this.$store.state.documentInSended.items.items) &&
            this.$store.state.documentInSended.items.items !== undefined
          ) {
            data = Object.keys(
              this.$store.state.documentInSended.items.items
            ).map((i) => this.$store.state.documentInSended.items.items[i]);
          } else {
            data = this.$store.state.documentInSended.items.items;
          }
        }
      }
      return data;
    },
    filters() {
      let filt = {};
      if (this.$store.state.documentInSended.items !== undefined) {
        if (this.$store.state.documentInSended.items.filters !== undefined) {
          filt = this.$store.state.documentInSended.items.filters;
        }
      }
      return filt;
    },
    state() {
      return this.$store.state.documentInSended;
    },
    forbidUpdate() {
      if (this.$store.state.profile.permissions.document_in) {
        return !this.$store.state.profile.permissions.document_in.update;
      }
      return false;
    },
    forbidRead() {
      if (this.$store.state.profile.permissions.document_in) {
        return !this.$store.state.profile.permissions.document_in.read;
      }
      return false;
    },
    forbidDel() {
      if (this.$store.state.profile.permissions.document_in) {
        return !this.$store.state.profile.permissions.document_in.delete;
      }
      return false;
    },
    canSeeAll() {
      if (this.$store.state.profile.permissions.document_in_all) {
        return this.$store.state.profile.permissions.document_in_all.read;
      }
      return false;
    },
  },
  mounted() {
    const state = {
      loaded: false,
      items: [],
      search: this.search,
      from: this.from,
    };
    this.$store.commit("documentInSended/STATE", state);
    this.get(state);
  },
  methods: {
    onSearch(val) {
      this.search = val;
    },
    changeDt() {
      // this.$children[0].$refs.table.reload();
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        search: this.search,
        cari: this.cari,
        cari2: this.cari2,
        cari3: this.cari3,
        asal: this.asal,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      this.$store.commit("documentInSended/STATE", state);
      this.get(state);
    },
    get(val) {
      this.$store.dispatch("documentInSended/getDocumentInSended", val);
    },
    resetInputs() {
      this.cari = "";
      this.cari2 = "";
      this.cari3 = "";
      this.asal = "";
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        search: this.search,
        cari: this.cari,
        cari2: this.cari2,
        cari3: this.cari3,
        asal: this.asal,
      };
      this.$store.commit("documentInSended/STATE", state);
      this.get(state);
      // this.$children[0].$refs.table.reload();
    },
    doDetail(val) {
      this.$store.dispatch("documentInSended/onDetail", val);
    },
    doDetailTL(val) {
      this.$store.dispatch("documentIn/onDetailTL", val);
    },
    getFrom() {
      const state = {
        search: this.search,
        from: this.from,
      };
      this.$store.commit("documentInSended/STATE", state);
      this.get(state);
    },
    // doEdit(val) {
    //   this.$store.dispatch("documentInSended/onEdit", val);
    // },
    // doDelete(val) {
    //   this.$store.dispatch("documentInSended/submitDelete", val);
    // }
  },
};
</script>
